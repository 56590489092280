<template>
    <div class="pages">
        <pageHeader></pageHeader>
        <div class="box">
            <div class="main">
                <div class="main-left">
                    <span class="pages-title">
                        我的订阅
                    </span>
                    <div class="programme">
                        <span class="programme-title">方案选择</span>
                        <div class="programme-lists" v-if="packageLists.length > 0">
                            <div class="programme-item" :class="packageIdx === index ? 'programme-item-active' : ''"
                                v-for="(item, index) in packageLists" :key="item.id" @click="packageIdx = index">
                                <!-- <div class="subscript">推荐</div> -->
                                <span class="item-name">{{ item.name }}</span>
                                <span class="item-price">
                                    <span class="item-price-fuhao">￥</span>
                                    <span class="item-price-num">{{ item.price }}</span>
                                    <span class="item-price-guige">/{{ item.name[0] }}</span>
                                </span>
                                <span class="item-remark">{{ item.remark }}</span>
                            </div>
                        </div>
                        <div class="buy-box">
                            <span class="buy-box-fuhao">￥</span>
                            <span class="buy-box-num" v-if="packageLists.length > 0">{{ packageLists[packageIdx].price
                            }}</span>
                            <div class="go-pay" @click="subscription">立即扫码订阅</div>
                        </div>
                    </div>
                    <span class="pages-title">
                        订阅尊享权益<span>订阅尊享10+项权</span>
                    </span>
                    <div class="quanyi" v-html="packageLists[packageIdx].benefit">

                    </div>
                </div>
                <div class="main-right">
                    <homeRight @loginOpen="isShowLogin = true"></homeRight>
                </div>
            </div>
        </div>
        <pageFooter></pageFooter>
        <payBox :isShow="isShowPay" :price="packageLists[packageIdx].price" @close="isShowPay = false" :payType="1"
            :payId="packageLists[packageIdx].id" :orderInfo="orderInfo"></payBox>
        <loginBox :isShow="isShowLogin" @close="isShowLogin = false"></loginBox>
        <bindBox :show="isShowBindBox" @close="isShowBindBox = false" :bindCode="bindCode"></bindBox>
    </div>
</template>

<script>
import loginBox from '@/components/loginBox.vue'
import bindBox from '@/components/bindBox.vue'
import pageHeader from '@/components/pageHeader.vue'
import pageFooter from '@/components/pageFooter.vue'
import homeRight from '@/components/homeRight.vue'
import payBox from '@/components/payBox.vue'
import { getPacakges } from '@/api/subscription.js'

export default {
    name: "subscription",
    components: {
        pageHeader,
        pageFooter,
        homeRight,
        payBox,
        loginBox,
        bindBox
    },
    data() {
        return {
            packageLists: [],//订阅方案
            packageIdx: 0,//选择的订阅方案
            isShowPay: false,//是否展示扫码支付框
            isShowLogin: false,//是否展示登录框
            token: '',
            orderInfo: {},
            bindCode: '',//绑定码
            isShowBindBox: false,//绑定手机号弹窗是否展示
        }
    },
    created() {
        this.token = this.getToken()
        if (this.$store.state.bindCode && !this.getToken()) {
            this.bindCode = this.$store.state.bindCode
            this.isShowBindBox = true
        }
        this.getPacakges()
    },
    methods: {
        /**
         * 获取订阅方案
         */
        getPacakges() {
            getPacakges().then(res => {
                this.packageLists = res
            })
        },
        /**
         * 拉起支付框
         */
        subscription() {
            if (!this.token) {
                this.isShowLogin = true
                return
            }
            this.orderInfo = {
                title: this.packageLists[this.packageIdx].name
            }
            this.isShowPay = true
        }
    }
}
</script>

<style scoped>
.pages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 120px);
    background-color: #F7F7F8;
}

.main {
    width: 1200px;
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.main-left {
    width: 888px;
    min-height: 1075px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    background-color: #ffffff;
}

.pages-title {
    margin: 18px 28px;
    color: #06121E;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.pages-title span {
    color: #8E8E8E;
    font-size: 12px;
    margin-left: 8px;
}

.programme {
    width: 888px;
    margin-top: 38px;
    border-bottom: 1px solid #E8E8E8;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 36px;
}

.programme-title {
    width: 828px;
    color: #666666;
    font-size: 16px;
    margin-left: 60px;
}

.programme-lists {
    width: 672px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
}

.programme-item {
    width: 208px;
    height: 284px;
    background: linear-gradient(#F5F5F5 100%, #FFFFFF 0%);
    border-radius: 10px;
    border: 1px solid #EFEFEF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.programme-item-active {
    background: rgba(221, 7, 34, 0.03);
    border: 1px solid #DD0722;
}

.programme-item .subscript {
    position: absolute;
    background-color: #E2450A;
    top: 0;
    left: 0;
    color: #FFFFFF;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 24px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.programme-item .item-name {
    color: #06121E;
    font-size: 20px;
}

.programme-item .item-price {
    margin-top: 46px;
    display: flex;
    align-items: center;
}

.programme-item .item-price .item-price-fuhao {
    color: #E2450A;
    font-size: 20px;
}

.programme-item .item-price .item-price-num {
    color: #E2450A;
    font-size: 35px;
    font-weight: bold;
}

.programme-item .item-price .item-price-guige {
    color: #5B5E63;
    font-size: 16px;
    margin-left: 8px;
}

.programme-item .item-remark {
    margin-top: 44px;
    color: #5B5E63;
    font-size: 16px;
}

.buy-box {
    margin-top: 60px;
    width: 888px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 36px;
}

.buy-box .buy-box-fuhao {
    color: #E2450A;
    font-size: 24px;
    margin-right: 4px;
}

.buy-box .buy-box-num {
    color: #E2450A;
    font-size: 40px;
    font-weight: bold;
    margin-right: 20px;
}

.buy-box .go-pay {
    width: 293px;
    height: 51px;
    background: #DD0722;
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
}

.quanyi {
    width: 888px;
}

.quanyi>>>* {
    max-width: 100%;
}

.main-right {
    width: 296px;
}
</style>
